import styled from "styled-components";

export const Section = styled.section`
  margin-bottom: 56px;
`;

export const DivItens = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .line {
    margin-top: -6rem;
    margin-bottom: 2rem;
    width: 75%;
    @media (max-width: 1200px) {
      width: 80%;
    }

    @media (max-width: 768px) {
      width: 80%;
    }

    @media (max-width: 480px) {
      width: 90%;
    }
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
`;

export const Person = styled.div`
  display: flex;
  alight-items: center;
  width: 70%;

  @media (max-width: 1200px) {
    width: 100%;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    width: 70%;
  }

  &.fernanda-reis {
    flex-direction: row-reverse;

    @media (max-width: 768px) {
      flex-direction: column;
      width: 70%;
    }

    @media (max-width: 500px) {
      width: 95%;
    }
  }

  @media (max-width: 500px) {
    width: 95%;
  }
`;

export const PersonImage = styled.img`
  width: auto;
  height: 500px;
  margin-right: 4rem;
  border-radius: 0.8rem;

  @media (max-width: 768px) {
    margin-bottom: 1rem;
    margin-right: 0;
    width: 100%;
  }

  &.fernanda-reis {
    margin-left: 4rem;
    border-radius: 0.8rem;
    @media (max-width: 768px) {
      margin-left: 0;
      width: 100%;
    }
  }
`;
export const PersonText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: nowrap;
  color: black;
  width: 75%;

  @media (max-width: 768px) {
    width: 100%;
    padding: 0 1rem;
    box-sizing: border-box;
  }

  h3 {
    margin: 0;
    font-size: 24px;
    line-height: 38px;
    font-weight: 600;

    @media (max-width: 768px) {
      font-size: 20px;
    }
  }

  p {
    margin-top: 0.5rem;
    font-size: 14px;
    line-height: 27px;

    @media (max-width: 768px) {
      font-size: 16px;
      line-height: 24px;
    }
  }

  &.fernanda-reis {
    p {
      text-align: right;
    }

    h3 {
      text-align: right;
    }
  }
`;

export const Separator = styled.div`
  img {
    margin: 4rem 0;
  }
`;
