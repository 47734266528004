import styled from "styled-components";

export const CarouselSection = styled.div`
  position: relative;
  margin: -10rem 0;
  margin-bottom: 2rem;
  width: 50%;

  .carousel .control-arrow {
    background: transparent;
    border: none;
    margin: 0 0.5rem;
    box-shadow: none !important; /* Remove shadow */
    background-color: transparent !important;
    color: transparent !important;
  }

  .carousel .control-arrow:before {
    border: solid #00b6ba;
    border-width: 0 4px 4px 0;
    display: inline-block;
    padding: 8px;
  }

  .carousel .control-next.control-arrow:before {
    transform: rotate(-45deg);
  }

  .carousel .control-prev.control-arrow:before {
    transform: rotate(135deg);
  }

  .carousel .control-arrow:hover:before,
  .carousel .control-arrow:focus:before,
  .carousel .control-arrow:active:before {
    border-color: #00b6ba; /* Ensure color stays the same on hover, focus, and active */
    box-shadow: none !important; /* Remove shadow on click */
    background-color: transparent !important;
    color: transparent !important;
  }

  .carousel .control-dots .dot {
    background: #00b6ba;
    border-radius: 0;
    width: 12px;
    height: 12px;
  }

  .carousel .control-dots .dot.selected {
    background: #f2b349;
  }

  @media (max-width: 768px) {
    width: 80%;
    margin: -1rem 0;
    margin-bottom: 2rem;
  }

  @media (max-width: 500px) {
    width: 100%;
    margin: -1rem 0;
    margin-bottom: 2rem;
  }
`;

export const CarouselItem = styled.div`
  background-color: #ffffff;
  padding: 1rem 4rem 5rem 4rem;
  color: #05036f;
  text-align: left;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 3rem 0px;

  @media (max-width: 768px) {
    box-shadow: none;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  width: 10%;
`;

export const CarouselTitle = styled.h2`
  color: rgb(64, 64, 64);
  font-size: 28px;
  font-weight: 600;
  margin-right: 1rem;
`;

export const TitleImage = styled.img`
  width: 8px;
  height: 8px;
`;

export const CarouselContent = styled.div`
  color: rgb(64, 64, 64);

  p {
    font-size: 16px;
    line-height: 27px;
  }
`;
