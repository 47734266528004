import React, { useEffect, useState } from "react";
import { Grid, Col } from "react-flexbox-grid";
import { useTranslation } from "react-i18next";
import { TopContainer, RowLine, SliderContainer, ArrowWrapper, GridWrapper } from "./style";
import Latam from "../../components/SlideCards/Latam";
import ArrowGoBg from "../../assets/svg/arrow-go-bg.svg";
import Map1 from "../../assets/images/map-1.png";
import Map2 from "../../assets/images/map-2.png";
import Map3 from "../../assets/images/map-3.png";
import Map4 from "../../assets/images/map-4.png";
import Map5 from "../../assets/images/map-5.png";
import Map6 from "../../assets/images/map-6.png";
import Map7 from "../../assets/images/map-7.png";
import Map8 from "../../assets/images/map-8.png";
import Arrow from "./Arrow";
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";

const OurPlans = () => {
  const { t } = useTranslation();
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [sliderRef, instanceRef] = useKeenSlider({
    slides: {
      perView: 3,
      spacing: 12,
    },
    breakpoints: {
      "(max-width: 768px)": {
        slides: {
          perView: 2,
          spacing: 12,
        },
      },
      "(max-width: 640px)": {
        slides: {
          perView: 1,
          spacing: 12,
        },
      },
    },
    initial: 0,
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    created() {
      setLoaded(true);
    },
  });

  // useEffect(() => {
  //   localStorage.setItem("planStorage", JSON.stringify({}));
  // }, []);

  const plans = [
    {
      id: 1,
      name: "lbl_worldl",
      price: "R$ 150,00",
      unit_price: 150.0,
      map: Map8,
      price_test: "*",
      description: [
        "lbl_worldwide_level_search",
        "lbl_coverage_around_200",
        "lbl_access_valid",
        "lbl_saved_searches",
      ],
    },
    {
      id: 2,
      name: "lbl_latinAmerica_Caribbean",
      price: "R$ 150,00",
      unit_price: 150.0,
      map: Map1,
      price_test: "*",
      description: [
        "lbl_regional_level_search",
        "lbl_coverage_around_40",
        "lbl_access_valid",
        "lbl_saved_searches",
      ],
    },
    {
      id: 3,
      name: "lbl_North_Alerica",
      price: "R$ 150,00",
      unit_price: 150.0,
      map: Map2,
      price_test: "*",
      description: [
        "lbl_regional_level_search",
        "lbl_coverage_around_5",
        "lbl_access_valid",
        "lbl_saved_searches",
      ],
    },
    {
      id: 4,
      name: "lbl_Central_EU_Asia",
      price: "R$ 150,00",
      unit_price: 150.0,
      map: Map4,
      price_test: "*",
      description: [
        "lbl_regional_level_search",
        "lbl_coverage_around_50",
        "lbl_access_valid",
        "lbl_saved_searches",
      ],
    },
    {
      id: 5,
      name: "lbl_Middle_East_North_African",
      price: "R$ 150,00",
      unit_price: 150.0,
      map: Map3,
      price_test: "*",
      description: [
        "lbl_regional_level_search",
        "lbl_coverage_around_20",
        "lbl_access_valid",
        "lbl_saved_searches",
      ],
    },
    {
      id: 6,
      name: "lbl_sub_Saharan_African",
      price: "R$ 150,00",
      unit_price: 150.0,
      map: Map7,
      price_test: "*",
      description: [
        "lbl_regional_level_search",
        "lbl_coverage_around_40",
        "lbl_access_valid",
        "lbl_saved_searches",
      ],
    },
    {
      id: 7,
      name: "lbl_South_Asian",
      price: "R$ 150,00",
      unit_price: 150.0,
      map: Map5,
      price_test: "*",
      description: [
        "lbl_regional_level_search",
        "lbl_coverage_around_10",
        "lbl_access_valid",
        "lbl_saved_searches",
      ],
    },
    {
      id: 8,
      name: "lbl_East_Asia_Pacift",
      price: "R$ 150,00",
      unit_price: 150.0,
      map: Map6,
      price_test: "*",
      description: [
        "lbl_regional_level_search",
        "lbl_coverage_around_40",
        "lbl_access_valid",
        "lbl_saved_searches",
      ],
    },
  ];

  return (
    <>
      <TopContainer fluid>
        <div
          style={{
            background: `#05036F url(${ArrowGoBg})`,
            paddingTop: "30px",
            paddingBottom: "40px",
            backgroundClip: "cover",
            backgroundRepeat: "no-repeat",
          }}
        >
          <Grid>
            <Col style={{ paddingBottom: "30px" }}>
              <h3>{t("lbl_our_plans")}</h3>
              <RowLine />
            </Col>
          </Grid>
        </div>
        <GridWrapper>
          <SliderContainer ref={sliderRef} className="keen-slider">
            {plans.map((item) => {
              return (
                <Latam
                  key={item.name}
                  id={item.id}
                  name={item.name}
                  map={item.map}
                  price={item.price}
                  unit_price={item.unit_price}
                  price_test={item.price_test}
                  description={item.description}
                />
              );
            })}
          </SliderContainer>
          {loaded && instanceRef.current && (
            <ArrowWrapper>
              <Arrow
                left
                disabled={currentSlide === 0}
                onCurrentSlideChange={(e) => e.stopPropagation() || instanceRef.current?.prev()}
              />
              <Arrow
                disabled={currentSlide === instanceRef.current.track.details.slides.length - 1}
                onCurrentSlideChange={(e) => e.stopPropagation() || instanceRef.current?.next()}
              />
            </ArrowWrapper>
          )}
        </GridWrapper>
      </TopContainer>
    </>
  );
};

export default OurPlans;
