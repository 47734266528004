import React from "react";
import {
  Section,
  Header,
  Slide,
  SlideChamada,
  Title,
  Excerpt,
  SlideImage,
  Image,
} from "./style";
import ComputadorAboutUs from "../../../assets/svg/AboutUs/aboutUs.png";
import CarouselComponent from "../carrousel";
import { DivContainer } from "./style";
import { DivRow } from "./style";
import { BodyContainer } from "./style";
import Midlevel from "../mid-level";
import { useTranslation } from "react-i18next";
const HighLevel = () => {
  const { t } = useTranslation();

  const data = {
    title: t("lbl_who_we_are"),
    content: `
    <p>${t("about_us_paragraph_1")}</p>
    
    <p>${t("about_us_paragraph_2")}</p>
    
    <p>${t("about_us_paragraph_3")}</p>
  `,
    image: ComputadorAboutUs,
  };

  return (
    <Section id="banner" className="bottom">
      <BodyContainer>
        <Header style={{ color: "05036F" }}></Header>
        <Slide>
          <DivContainer
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}>
            <Title>{data.title}</Title>
            <DivRow className="item">
              <SlideChamada style={{ flex: 1 }}>
                <Excerpt dangerouslySetInnerHTML={{ __html: data.content }} />
              </SlideChamada>
              <SlideImage>
                <Image src={data.image} alt="Slide" />
              </SlideImage>
            </DivRow>
          </DivContainer>
        </Slide>
        <CarouselComponent />
      </BodyContainer>
    </Section>
  );
};

export default HighLevel;
